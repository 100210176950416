
// Converted Variables


// Custom Media Query Variables


/*

    NESTED
    Tachyons module for styling nested elements
    that are generated by a cms.

*/

.nested-copy-line-height p,
.nested-copy-line-height ul,
.nested-copy-line-height ol {
  line-height: $line-height-copy;
}

.nested-headline-line-height h1,
.nested-headline-line-height h2,
.nested-headline-line-height h3,
.nested-headline-line-height h4,
.nested-headline-line-height h5,
.nested-headline-line-height h6 {
  line-height: $font-size-4;
}

.nested-list-reset ul,
.nested-list-reset ol {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

.nested-copy-indent p+p {
  text-indent: $letter-spacing-1;
  margin-top: $spacing-none;
  margin-bottom: $spacing-none;
}

.nested-copy-seperator p+p {
  margin-top: $spacing-copy-separator;
}

.nested-img img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.nested-links a {
  color: $blue;
  transition: color .15s ease-in;
}

.nested-links a:hover,
.nested-links a:focus {
  color: $light-blue;
  transition: color .15s ease-in;
}
